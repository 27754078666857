/* EventsPage.css */
.events-page {
  display: flex;
  background: #007fa3; 
  overflow: hidden;
}

.sidebar {
  flex: 0 0 250px; 
}

.event-content {
  flex-grow: 0.9755;
  background-color: #ffffff; 
  border-radius: 8px; 
  padding: 2rem; 
  margin-left: 1rem; 
  margin-right: 1rem;
  border-left: 2px solid #4b9ce2; /*Blue border on the left and right*/
  border-right: 2px solid #4b9ce2; 
  width: calc(100% - 250px - 2rem); /* Adjust width to account for sidebar and margins */
  margin-top: 1rem; /* Provide some space at the top and bottom */
  margin-bottom: 4rem; 
  height: auto; /* Height is determined by content */
}

.event-header,
.event-info,
.event-description {
  border-radius: 4px; /* Rounded corners for child elements */
  padding: 1rem; /* Padding for child elements */
  margin-bottom: 1rem; 
}

.placeholder-photo {
  width: 95%; 
  max-height: 250px; 
  object-fit: cover; 
  border-radius: 4px;
  margin-bottom: 1rem;
  margin-left: 1rem;
}

.event-info {
  display: flex;
  justify-content: space-between; /* Align button and info */
  align-items: flex-start;
  margin-bottom: 1rem;
  flex-direction: column;
}

.rsvp-button {
  background-color: #004aad; 
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px; /* Rounded corners for the button */
  cursor: pointer; /* Pointer cursor for clickable button */
  display: block; /* Block display to occupy full width */
  width: auto; 
  margin: 1rem 0; /* Margin around the button */
}

.event-rating span {
  display: block; 
}


.people-going {
  display: block; 
}

.back-button {
  background-color: #ffffff;
  color: #000000;
  border: none;
  cursor: pointer;
  margin-left: 0.7rem;
}

.event-star-rating span {
  color: gold; 
  font-size: 1rem; 
  margin: 0 2px; 
}

.event-page-box-review {
  border: 1px solid #ccc; 
  padding: 15px; 
  margin-bottom: 15px; 
  border-radius: 5px; 
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); 
  background-color: #f9f9f9; 
}

.author-name {
  font-weight: bold;
}

.comment-rating {
  display: flex;
  align-items: center;
}

.comment-rating .star-rating {
  margin-right: 10px;
}


.post-date {
  font-style: italic;
  color: grey;
  margin-top: 5px;
}

