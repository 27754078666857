.sidebar {
  width: 200px;
  background-color: #25355a;
  color: #fff;
  padding: 20px;
  min-height: 100vh; /* This makes the sidebar stretch full height */
  overflow-y: auto;
}

.btn {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #555;
  border: none;
  color: #fff;
  cursor: pointer;
}

.sidebar-btn:hover {
  background-color: #666;
}

.sidebar-logo-img {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.sidebar-logo-img:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

.logo-img {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.logo-img:hover {
  transform: scale(1.1);
  opacity: 0.8;
}
