/* ReviewPage.css */
.rp-page-container {
  display: flex;
  background: #007fa3;
  overflow: hidden;
}

.rp-review-container {
  flex-grow: 1;
  background-color: #ffffff; 
  border-radius: 8px; 
  padding: 2rem; 
  margin-left: 1rem; 
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.rp-input-group label {
  color: #25355a;
  font-size: 1rem; 
}

.rp-input-group {
  margin-bottom: 1.5rem; 
}

.rp-input[type="text"],
.rp-textarea {
  width: 100%; 
  padding: 0.75rem; 
  border: 1px solid #d3dce6; 
  border-radius: 4px; 
  font-size: 1rem; 
}

.rp-submit-review-btn {
  background-color: #4b9ce2; /* Match dashboard button color */
  color: white;
  border: none;
  border-radius: 4px; 
  padding: 0.75rem 1.5rem; 
  font-size: 1rem; 
  cursor: pointer; /* Pointer cursor for clickable elements */
  display: block; 
  width: 100%; /* Full-width button if matches the dashboard */
  margin-top: 1rem; /* Space above the button */
}

.rp-not-submit-review-btn {
  background-color: lightgray; /* Can't review */
  color: white;
  border: none;
  border-radius: 4px; 
  padding: 0.75rem 1.5rem; 
  font-size: 1rem; 
  cursor: pointer; /* Pointer cursor for clickable elements */
  display: block; 
  width: 100%; /* Full-width button if matches the dashboard */
  margin-top: 1rem; /* Space above the button */
}

.rp-sidebar {
  width: 250px; 
  flex-shrink: 0; /* Prevent the sidebar from shrinking */
}

.rp-page-container {
  display: flex;
  height: 100vh; /* Full viewport height */
}

.rp-input-group label {
  display: block; /* ensures the label is on top of the input/textarea */
  margin-bottom: 0.5rem; 
  color: #25355a;
  font-size: 1rem;

}

.rp-textarea {
  height: 200px; /* ensures the height is determined by the rows attribute */
  width: 97.5%; 
  padding: 0.75rem; 
  border: 1px solid #d3dce6; 
  border-radius: 4px; 
  font-size: 1rem; 
  margin-bottom: 1.5rem; 
  resize: vertical; 
}

.rp-form-container {
  max-width: 500px; 
  margin: auto; /* center the container */
}

.star-rating {
  display: inline-block;
}

.star-rating button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.star {
  font-size: 25px; 
  color: #ccc;
}

.on .star {
  color: #FFD700; /* Gold color for active stars */
}

.off .star {
  color: #e4e5e9; /* Lighter color for inactive stars */
}

.back-button {
  background-color: #ffffff;
  color: #000000;
  border: none;
  cursor: pointer;
}


