/* Dashboard.css */
.dashboard {
  display: flex;
  flex-direction: row; /* This will place sidebar and main content side by side */
  min-height: 100vh; 
  background-color: #007fa3;
  overflow: hidden;
}

.btn {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #555;
  border: none;
  color: #fff;
  cursor: pointer;
}

.btn:hover {
  background-color: #ffffff;
  color: #25355a;
}

.search-bar {
  width: 50%;
  height: 40px;
  border-radius: 20px;
  padding-left: 35px; /* Increase padding to make space for the icon */
  border: 1px solid #ccc;
  outline: none;
  background-image: url("./images/searchIcon.png"); /* Path to your icon */
  background-position: 10px center; /* Position the icon */
  background-repeat: no-repeat; /* Prevent the icon from repeating */
  background-size: 20px; /* Adjust the size of your icon */
  border-radius: 5px;
}

.filter-btn {
  width: 100px;
  height: 44px;
  margin-right: auto;
  border-radius: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  cursor: pointer;
}

.user-btn {
  display: inline-flex; /* Changed to inline-flex to better control the button size */
  align-items: center; /* This will vertically center the items in the button */
  justify-content: center;
  padding: 10px 20px; /* Adjusted padding for better spacing */
  background-color: #ffffff;
  border: 1px solid #ddd; /* Added a subtle border for definition */
  color: #25355a;
  cursor: pointer;
  font-size: 1em;
  text-decoration: none; /* Assuming you want the button to look like a link */
  border-radius: 10px; /* Optional: rounded corners for the button */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: some shadow for a "lifted" effect */
  transition: all 0.3s ease; /* Optional: transition for hover effects */
}

.user-btn:hover {
  background-color: #f5f5f5; /* Slight background change on hover for interactive feedback */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: more pronounced shadow on hover */
}

.user-btn-img {
  height: 30px; /* Increased height */
  width: 30px; /* Increased width */
  margin-right: 10px; /* Retained spacing to the right of the image */
  object-fit: contain; /* Ensures the image keeps its aspect ratio */
}

/* Additional styles for the text in the button, if needed */
.user-btn-text {
  display: block;
  margin: 0; /* Adjust the margin as needed */
}

.user-btn:hover {
  background-color: #3842c7; /* Change this to the desired hover background color */
  color: #fff; /* Change this to the desired hover font color */
}

.main-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  overflow: auto;
  flex-grow: 1; /* This will allow the main content to grow and fill the space */
  overflow-y: auto;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.category-content {
  background-color: #eee;
  padding: 20px;
  border-radius: 10px; /* This will give you rounded corners */
  margin-bottom: 15px; /* Adjust this value as needed */
}

.event-container {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
}

.event-card {
  display: flex;
  position: relative;
  width: 200px;
  height: 200px;
  margin-right: 10px;
}

.event-container::-webkit-scrollbar {
  width: 0.7em;
  background: white;
}
.event-container::-webkit-scrollbar-thumb {
  background: lightgray;
  height: 30px;
}

.event-container::-webkit-scrollbar-track-piece
{
   display: none;
}

.event-container {
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: 0.7em; /* Firefox */
}

.event-image {
  width: 200px;
  height: 200px;
}

.event-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 57px;
}

.event-details {
  display: flex;
  text-align: center;
  border-bottom: 1px solid #007fa3;
}

.event-text {
  position: relative;
  width: 100%;
  cursor: pointer;
  padding: 5px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
}

.event-text:hover {
  background-color: #08ea9f; /* Color when hovering over the name */
}

.review-btn {
  position: relative;
  width: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  padding: 5px;
  overflow: hidden;
  justify-content: center;
}

.review-btn:hover {
  background-color: #08ea9f; /* Color when hovering over the name */
}

.logo-img {
  width: 100px; /* or any size you prefer */
  height: auto;
  margin-bottom: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
