.page-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  align-items: flex-start; /* Align content to the start (top) of the container */
}



.content-container {
  margin-left: 250px; /* Add margin to account for sidebar width */
  width: calc(100% - 250px); /* Adjust width to account for sidebar */
  display: flex;
  flex-direction: column; /* Stack metrics and chart vertically */
}


.metrics-container {
  flex: 1; /* Takes up the remaining space */
  display: flex;
  flex-direction: column; /* Stacks the content vertically */
  padding: 20px;
  margin-left: 20px;
  align-items: center; /* Aligns the cards horizontally in the center */
}

.metric-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px; /* Adjust as needed to reduce the space */
  width: 80%; /* Example width, adjust as needed */
}

.metric-card:last-child {
  margin-bottom: 0;
}

.metric-card h2 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  color: #25355A; /* Matching your sidebar color */
  margin-bottom: 15px;
}

.metric-card p {
  font-size: 24px;
  color: #333;
  margin: 0;
}

.chart-container {
  align-self: center; /* Center the chart container within the content */
  width: 100%; /* Adjust the width of the chart as needed */
  max-width: 600px; /* Example max-width, adjust as needed */
  margin-top: 20px; /* Space above the chart */
  margin-bottom: 20px; /* Space below the chart */
}