.search-results-dashboard {
  display: flex;
  background-color: #007fa3;
}

.search-results-main-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.search-results-container {
  background-color: #eee;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.results {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.result-card {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
