/* AddEventPage.css */
.add-event-container {
    display: flex;
  }

.add-event-page {
    width: 100%;
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    flex-grow: 1;
  }
  
  .add-event-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .add-event-form input[type="text"],
  .add-event-form input[type="datetime-local"],
  .add-event-form input[type="number"],
  .add-event-form textarea,
  .add-event-form select {
    width: 100%;
    max-width: 540px; /* Limit the maximum width of the inputs */
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .add-event-form textarea {
    height: 100px;
    resize: vertical;
  }
  
  .add-event-form label {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .add-event-form button {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-event-form button:hover {
    background-color: #0056b3;
  }