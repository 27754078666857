.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.login-box {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.input-field {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 300px;
}

.button {
  padding: 10px 20px;
  background-color: #25355a;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.have-account-button {
  background-color: #ffffff;
  color: #000000;
  border: none;
  cursor: pointer;
}

.invalid-login {
  background-color: #ffffff;
  color: red;
  border: none;
}

.button:hover {
  background-color: #007fa3;
}
