.profile-page {
  display: flex;
  background-color: #25355a;
  overflow: hidden;
}

.profile-page-container {
  background-color: rgb(216, 222, 224);
  padding: 25px;
  width: 100%;
}

.profile-page-event-container {
}

.profile-page-event-item {
  background-color: white;
  padding: 25px;
  margin-bottom: 25px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.profile-page-review-container {
}

.profile-page-review-item {
  background-color: white;
  padding: 25px;
  margin-bottom: 25px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  /* display: flex;
  flex-direction: row; */
}

.profile-page-rsvp-container {
}

.profile-page-rsvp-item {
  background-color: white;
  padding: 25px;
  margin-bottom: 25px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  /* display: flex;
  flex-direction: row; */
}

.profile-page-button-delete {
  border: none;
  border-radius: 5px;
  width: 80px;
  padding: 10px;
  background-color: black;
  color: white;
  margin: 5px;
}

.profile-page-button-delete:hover {
  background-color: red;
}

.profile-page-button-confirm {
  border: none;
  border-radius: 5px;
  width: 80px;
  padding: 10px;
  background-color: black;
  color: white;
  margin: 5px;
}

.profile-page-button-confirm:hover {
  background-color: green;
}

.profile-page-button {
  border: none;
  border-radius: 5px;
  width: 80px;
  padding: 10px;
  margin: 5px;
  background-color: black;
  color: white;
}
